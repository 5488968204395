<template>
  <section class="zhulifeiDetails">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <el-image :src="options.img0" class="img1"></el-image>
      <el-row :gutter="30" class="main">
        <el-col :span="12"><el-image :src="options.img1"></el-image></el-col>
        <el-col :span="12">
          <el-row class="details" :gutter="20">
            <el-col :span="6"><el-image :src="options.img2"></el-image></el-col>
            <el-col :span="18">
              <strong>四大抓手</strong>
              <el-row :gutter="10"><el-col :span="2"><span>1、</span></el-col><el-col :span="22"><p>企业版拼多多（原厂集中供货）</p></el-col></el-row>
              <el-row :gutter="10"><el-col :span="2"><span>2、</span></el-col><el-col :span="22"><p>共享库存（协助用户去库存）</p></el-col></el-row>
              <el-row :gutter="10"><el-col :span="2"><span>3、</span></el-col><el-col :span="22"><p>区块链技术（保证数据安全）</p></el-col></el-row>
              <el-row :gutter="10"><el-col :span="2"><span>4、</span></el-col><el-col :span="22"><p>嵌入式的平台（免安装，并可部署在各种移动终端）</p></el-col></el-row>
            </el-col>
          </el-row>
          <el-row class="details" :gutter="20">
            <el-col :span="6"><el-image :src="options.img3"></el-image></el-col>
            <el-col :span="18">
              <strong>一座基石</strong>
              <p>一套“活着”的智慧供应链系统，拥有极强的用户粘性：</p>
              <el-row :gutter="10"><el-col :span="2"><span>1、</span></el-col><el-col :span="22"><p>能不断的自我演变以适应电商行业供应链和企业管理的变化；</p></el-col></el-row>
              <el-row :gutter="10"><el-col :span="2"><span>2、</span></el-col><el-col :span="22"><p>能够给用户不断带来订单的供应链系统；</p></el-col></el-row>
              <el-row :gutter="10"><el-col :span="2"><span>3、</span></el-col><el-col :span="22"><p>能够辅助公司的采购和销售的决策过程，不仅仅只是一个结果信息记录的对账工具。</p></el-col></el-row>
            </el-col>
          </el-row>
          <el-row class="details" :gutter="20">
            <el-col :span="6"><el-image :src="options.img4"></el-image></el-col>
            <el-col :span="18">
              <strong>三个优势</strong>
              <el-row :gutter="10"><el-col :span="2"><span>1、</span></el-col><el-col :span="22"><p>平台布置</p></el-col></el-row>
              <el-row :gutter="10"><el-col :span="2"><span>2、</span></el-col><el-col :span="22"><p>标准版本系统免费使用</p></el-col></el-row>
              <el-row :gutter="10"><el-col :span="2"><span>3、</span></el-col><el-col :span="22"><p>支持升级高级版及二次开发</p></el-col></el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-image :src="options.img5" class="img1"></el-image>
    </section>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
export default {
  name: 'zhulifei',
  components: {
    imageBanner,
  },
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/05-助力飞.png'),
        slogan: require('../../assets/images/common/slogan.png'),
      },
      options: {
        img0: require('../../assets/images/2023/zhulifei/页面2-0.png'),
        img1: require('../../assets/images/2023/zhulifei/页面2-1.png'),
        img2: require('../../assets/images/2023/zhulifei/页面2-2.png'),
        img3: require('../../assets/images/2023/zhulifei/页面2-3.png'),
        img4: require('../../assets/images/2023/zhulifei/页面2-4.png'),
        img5: require('../../assets/images/2023/zhulifei/页面图_01.png'),
      }
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      // let id = (this.$route.query.id).toString()
      // switch (id) {
      //   case '1':
      //     this.options = this.$store.getters.getTeam1 && JSON.parse(JSON.stringify(this.$store.getters.getTeam1))
      //     break
      //   case '2':
      //     this.options = this.$store.getters.getTeam2 && JSON.parse(JSON.stringify(this.$store.getters.getTeam2))
      //     break
      //   case '3':
      //     this.options = this.$store.getters.getTeam3 && JSON.parse(JSON.stringify(this.$store.getters.getTeam3))
      //     break
      //   case '4':
      //     this.options = this.$store.getters.getTeam4 && JSON.parse(JSON.stringify(this.$store.getters.getTeam4))
      //     break
      //   default:
      //     this.options = this.$store.getters.getTeam1 && JSON.parse(JSON.stringify(this.$store.getters.getTeam1))
      //     break
      // }
    }
  }
}
</script>

<style scoped>
.zhulifeiDetails {background-color: #ecf0f5; padding-bottom: 1rem;}
.container {padding: 1rem 10%;}
.container .el-image {width: 100%;}
.container p {line-height: 2; text-indent: 2em;}
.container .details {padding: 2rem;}
.container .details span {line-height: 2;}
.container .details p {text-indent: 0;}
.container .details .el-image , .container .details /deep/ img {padding: 0;}
.zhulifeiDetails .main {padding: 8rem 0; border-top: 1px solid #ccc;}
.zhulifeiDetails .img1 {padding: 4rem 0;}
</style>